import React from 'react';
import { IForm } from '../../../models/list-forms-model';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { NumberField } from '../../uikit/NumberField/NumberField';
import { SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import { FormField } from '../CardForms/CardFormSimple';

type Props = {
  field: FormField;
  setFieldValue: (fieldCode: string, value: string | null) => void;
};

export const FieldEdit = ({ field, setFieldValue }: Props) => {
  switch (field.type) {
    case 'Boolean': {
      return (
        <Checkbox
          checked={field.value === 'true'}
          handleChange={(flag) =>
            setFieldValue(field.code, flag ? 'true' : 'false')
          }
        />
      );
    }
    case 'Number': {
      return (
        <NumberField
          value={field.value === null ? null : Number(field.value)}
          handleChange={(value) =>
            setFieldValue(field.code, value === null ? null : String(value))
          }
        />
      );
    }
    case 'Enum':
    case 'Dictionary': {
      return (
        <SelectField
          values={field.value ? [field.value] : []}
          options={field.options || []}
          handleChange={(values) =>
            setFieldValue(field.code, values?.[0] || null)
          }
        />
      );
    }
    default:
      return (
        <TextField
          value={field.value ?? ''}
          handleChange={(value) => setFieldValue(field.code, value)}
        />
      );
  }
};
