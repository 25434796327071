import dayjs from 'dayjs';
import React, { useState } from 'react';
import { clsx } from 'clsx';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import styles from './DatePicker.module.css';
import { generateCalendar, getMonthName, reorderDays } from './helper';

type DatePickerProps = {
  date: Date | null;
  handleChange: (date: Date) => void;
};

export function DatePicker({ date, handleChange }: DatePickerProps) {
  const [currentMonthDate, setCurrentMonthDate] = useState(
    dayjs(date || new Date()).startOf('month').toDate()
  );

  const calendarDays = generateCalendar(currentMonthDate);
  const weekDays = reorderDays(calendarDays[0]);

  const isInactiveDay = (indexWeek: number, day: number): boolean => {
    return (
      (indexWeek === 0 && day >= 21) ||
      (calendarDays.length - 1 === indexWeek && day <= 7)
    );
  };

  const changeMonth = (delta: number) => {
    setCurrentMonthDate((prevState) => {
      return dayjs(prevState).add(delta, 'month').toDate();
    });
  };

  return (
    <div className={styles.Container}>
      <div className={styles.Header} style={{}}>
        <div className={styles.HeaderControls}>
          <ButtonIcon
            iconName="ChevronLeft"
            iconProps={{ style: { fill: 'var(--color-main)' } }}
            onClick={() => changeMonth(-1)}
          />
        </div>
        <div
          className={styles.HeaderCenter}
        >{`${getMonthName(currentMonthDate)} ${dayjs(currentMonthDate).format('YYYY')}`}</div>
        <div className={styles.HeaderControls}>
          <ButtonIcon
            iconName="ChevronRight"
            iconColor="var(--color-main)"
            onClick={() => changeMonth(1)}
          />
        </div>
      </div>
      <table className={styles.Calendar}>
        <thead>
          <tr className={styles.HeadWeekDays}>
            {weekDays.map((day) => (
              <th key={day} className={styles.HeadWeekDay}>
                {day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendarDays.map((week, indexWeek) => (
            <tr>
              {week.map((day) => (
                <td
                  key={day.day}
                  className={clsx(styles.WeekDay, {
                    [styles.InactiveDay]: isInactiveDay(indexWeek, day.day),
                    [styles.CurrentDay]: dayjs(day.date).isSame(
                      dayjs(date).startOf('day')
                    ),
                  })}
                  onClick={() => handleChange(day.date)}
                >
                  {day.day}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
