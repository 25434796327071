import React from 'react';
import { Button } from '../Button/Button';
import Modal from './Modal';

import styles from './ModalDelete.module.css';

interface ModalDeleteProps {
  open?: boolean;
  handleDelete: () => void;
  handleCancel: () => void;
}

const ModalDelete = ({ open = true, handleDelete, handleCancel }: ModalDeleteProps) => {
  return (
    <Modal
    open={open}
    title="Удалить объект?"
    handleClose={handleCancel}
    hideTitleClose
  >
      <div className={styles.Content}>
        <div className={styles.Description}>
          <p>Вы действительно хотите удалить отмеченный объект?</p>
          <p>Восстановление будет невозможно</p>
        </div>
        <div className={styles.Control}>
          <Button onClick={handleCancel} variant="additional">Отменить</Button>
          <Button onClick={handleDelete} variant="delete">Удалить</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
