// Названия дней недели
const WEEK_DAYS = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВК"];

export type CalendarDays = {
  day: number,
  date: Date,
};

export const generateCalendar = (date?: Date | null): CalendarDays[][] => {
  const weeks = [];
  const targetDate = date ? new Date(date) : new Date();
  const year = targetDate.getFullYear();
  const month = targetDate.getMonth();

  // Первый и последний день текущего месяца
  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  // День недели первого числа (0 - воскресенье, 1 - понедельник, ...)
  let startWeekDay = firstDayOfMonth.getDay();
  if (startWeekDay === 0) startWeekDay = 7; // Переводим воскресенье в конец недели

  // Заполняем первую неделю датами предыдущего месяца
  const prevMonthDays = {
    day: new Date(year, month, 0).getDate(),
    date: new Date(year, month, 0)
  };
  let currentWeek = [];
  for (let i = 1; i < startWeekDay; i++) {
    const day = prevMonthDays.day - startWeekDay + i + 1;
    currentWeek.push({
      day,
      date: new Date(year, month-1, day),
    });
  }

  // Добавляем дни текущего месяца
  let currentDay = 1;
  while (currentDay <= lastDayOfMonth.getDate()) {
    while (currentWeek.length < 7) {
      if (currentDay > lastDayOfMonth.getDate()) break;
      const day = currentDay++;
      currentWeek.push({
        day,
        date: new Date(year, month, day),
      });
    }
    weeks.push(currentWeek);
    currentWeek = [];
  }

  // Добавляем оставшиеся дни недели из следующего месяца
  const lastWeek = weeks[weeks.length - 1];
  const lastWeekLength = lastWeek.length;
  for (let i = 1; i <= 7 - lastWeekLength; i++) {
    lastWeek.push({
      day: i,
      date: new Date(year, month + 1, i),
    });
  }

  return weeks;
};

export const reorderDays = (days: CalendarDays[]): string[] => {

  // Индекс числа 1
  const indexOfMonday = days.findIndex((day) => day.day === 1);

  // Проверка корректности входных данных
  if (indexOfMonday === -1 || days.length !== 7) {
    throw new Error("Массив должен содержать число 1 и быть длиной 7");
  }

  // Перестановка массива
  const reorderedWeekDays = [...WEEK_DAYS.slice(indexOfMonday), ...WEEK_DAYS.slice(0, indexOfMonday)];

  return reorderedWeekDays;
};

export const getMonthName = (date: Date) => {
  return date.toLocaleString('ru-RU', { month: 'long' })
}
