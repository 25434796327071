import React from 'react';
import { useParams } from 'react-router-dom';
import { BreadCrumbs } from '../components/common/BreadCrumbs/BreadCrumbs';
import { ListFormController } from '../components/forms/ListFormController/ListFormController';
import { FormsList, GroupsList, SubGroupsList } from '../models/list-forms-model';

export const ListFormPage = () => {

  const { formCode } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  const pathToListForm = `/forms/${form.groupCode}/${form.subGroupCode}/${form.code}`;

  const groupName = GroupsList.find((g) => g.code === form.groupCode)?.name;
  const subGroupName = SubGroupsList.find(
    (g) => g.code === form.subGroupCode
  )?.name;

  return (
    <>
      <div style={{padding: '0 24px'}}>
        <BreadCrumbs
          breadCrumbs={[
            {label: 'Главная', toLink: '/'},
            groupName ? {label: groupName} : undefined,
            subGroupName ? {label: subGroupName} : undefined,
            {label: form.name, toLink: pathToListForm},
          ]}
        />
      </div>
      <ListFormController form={form}/>
    </>
)
  ;
}
