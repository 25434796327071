import { clsx } from 'clsx';
import React, { useState, ChangeEvent, FocusEvent, forwardRef } from 'react';
import { Button } from '../Button/Button';
import { Icon, IconName } from '../Icon/Icon';

import styles from './TextField.module.css';

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: string; // Сообщение об ошибке
  warning?: string; // Сообщение-предупреждение
  disabled?: boolean; // Состояние disabled (неактивное)
  required?: boolean; // Обязательное поле
  // TODO не показывать кнопку очистки, когда строка пустая
  clearable?: boolean; // Можно очистить
  handleChange?: (text: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  startElements?: React.ReactNode[];
  endElements?: React.ReactNode[];
  inputRef?: React.RefObject<HTMLInputElement>;
};
export type Ref = HTMLDivElement;

// TODO добавить возможность иконки в начале и конце
export const TextField = forwardRef<Ref, TextFieldProps>(
  (
    {
      label,
      error,
      warning,
      disabled,
      required,
      clearable,
      handleChange,
      onChange,
      onFocus,
      onBlur,
      startElements,
      endElements,
      inputRef,
      ...otherInputProps
    },
    ref
  ) => {
    const [focused, setFocused] = useState<boolean>(false); // состояние для хранения фокуса инпута
    const [isShownErrorDescription, setIsShownErrorDescription] =
      useState(false);

    const errorIcon = error
      ? {
          description: error,
          iconName: 'ErrorFilled' as IconName,
          color: 'red',
        }
      : warning
        ? {
            description: warning,
            iconName: 'WarningFilled' as IconName,
            color: 'orange',
          }
        : undefined;

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>): void => {
      handleChange?.(e.target.value);
      onChange?.(e);
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
      setFocused(true);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>): void => {
      setFocused(false);
      onBlur?.(e);
    };

    const handleClearValue = () => {
      const inputElement = document.createElement('input');
      inputElement.value = ''; // Очищаем значение

      const event = {
        target: inputElement,
        currentTarget: inputElement,
        bubbles: true,
        cancelable: false,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: true,
        nativeEvent: new Event('input', { bubbles: true }),
        preventDefault: () => {},
        isDefaultPrevented: () => false,
        stopPropagation: () => {},
        isPropagationStopped: () => false,
        persist: () => {},
        timeStamp: Date.now(),
        type: 'change',
      };

      handleChangeInput(event);
    };

    const handleShowError = () => {
      setIsShownErrorDescription((flag) => !flag);
    };

    const classContainer = clsx(styles.inputContainer, {
      [styles.error]: error,
      [styles.warning]: warning,
      [styles.focused]: focused,
      [styles.disabled]: disabled,
    });
    const classLabel = clsx(styles.inputLabel, {
      [styles.inputRequired]: required,
    });
    const classInput = clsx(styles.inputField);

    return (
      <div ref={ref}>
        {label && <label className={classLabel}>{label}:</label>}
        <div className={classContainer}>
          {/*{startElements && startElements.map((Element) => Element)}*/}
          {startElements &&
            startElements.map((Element, index) => (
              <React.Fragment key={index}>{Element}</React.Fragment>
            ))}
          <input
            ref={inputRef}
            className={classInput}
            type="text"
            required={required}
            disabled={disabled}
            onChange={handleChangeInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...otherInputProps}
          />
          {clearable && (
            <Button
              onClick={handleClearValue}
              variant="text"
              iconEnd={<Icon name="Close" />}
              rounded
            />
          )}
          {errorIcon && (
            <Button
              onClick={handleShowError}
              variant="text"
              iconEnd={
                <Icon name={errorIcon.iconName} fill={errorIcon.color} />
              }
              rounded
            />
          )}
          {/*{endElements && endElements.map((Element) => Element)}*/}
          {endElements &&
            endElements.map((Element, index) => (
              <React.Fragment key={index}>{Element}</React.Fragment>
            ))}
        </div>
        {isShownErrorDescription && errorIcon && (
          <div
            className={clsx(styles.inputMessage)}
            style={{ backgroundColor: errorIcon.color }}
          >
            <span>{errorIcon.description}</span>
          </div>
        )}
      </div>
    );
  }
);
