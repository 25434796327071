import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { Icon, IconName, IconProps } from '../Icon/Icon';

type ButtonIcon = ButtonProps & {
  iconName: IconName;
  iconColor?: string;
  iconProps?: Partial<IconProps>;
};

export const ButtonIcon = ({
  iconName,
  iconColor,
  iconProps,
  overrideFillIcon = true,
  rounded = false,
  ...buttonProps
}: ButtonIcon) => {
  return (
    <Button
      variant="text"
      iconEnd={<Icon name={iconName} fill={iconColor} {...iconProps}/>}
      overrideFillIcon={!iconColor}
      rounded={rounded}
      {...buttonProps}
    />
  );
};
