import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';


interface PortalProps {
  children: React.ReactNode;
}

const Portal = ({ children }: PortalProps) => {
  const [container] = useState(() => document.createElement('div'));

  useEffect(() => {
    const root = document.getElementById('root');
    root!.appendChild(container);
    return () => {
      root!.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

export default Portal;
