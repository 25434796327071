import React from 'react';
import { Icon } from '../Icon/Icon';


type CheckboxProps = {
  label?: string;
  checked: boolean;
  handleChange?: (flag: boolean) => void;
  disabled?: boolean;
  size?: 'small' | 'normal'
}

// TODO отрефакторить....
export const Checkbox = ({ label, disabled = false, checked, handleChange, size = 'normal' }: CheckboxProps) => {
  // const [checked, setChecked] = useState(false);

  const onChange = () => {
    if (!disabled) {
      handleChange?.(!checked);
    }
  };

  const sizeSide = size === 'small' ? 16 : 22;

  const cursorStyle = disabled ? 'not-allowed' : handleChange ? 'pointer' : undefined;

  return (
    <label style={{
      display: 'flex',
      alignItems: 'center',
      cursor: cursorStyle,
      opacity: disabled ? 0.5 : 1
    }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          marginRight: label ? '8px' : 0,
          width: sizeSide,
          height: sizeSide,
          border: '1px solid var(--color-gray)',
          cursor: cursorStyle,
        }}
      >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        style={{
          appearance: 'none',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          cursor: cursorStyle,
        }}
      />
        {checked && <Icon name="Check" fill="#A8735D" style={{ cursor: cursorStyle }}/>}
      </div>
      {label && (<span>{label}</span>)}
    </label>
  );
};
