import React from 'react';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import Portal from '../Portal/Portal';

import styles from './Modal.module.css';

interface ModalProps {
  open?: boolean;
  title?: string;
  children: React.ReactNode;
  handleClose: () => void;
  hideTitleClose?: boolean;
}

const Modal = ({
  open = true,
  title,
  children,
  handleClose,
  hideTitleClose = false,
}: ModalProps) => {
  return open ? (
    <Portal>
      <div className={styles.Backdrop} onClick={() => handleClose()}>
        <div className={styles.Content} onClick={(e) => e.stopPropagation()}>
          {title && (
            <div className={styles.Title}>
              {title}
              {!hideTitleClose && (
                <ButtonIcon
                  iconName="Close"
                  rounded
                  onClick={() => handleClose()}
                />
              )}
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    </Portal>
  ) : null;
};

export default Modal;
