import { SVGProps } from 'react';
// IconsMenu
import { ReactComponent as Home } from 'assets/svg/home.svg';
import { ReactComponent as Filter } from 'assets/svg/filter.svg';
import { ReactComponent as Note } from 'assets/svg/note.svg';
import { ReactComponent as List } from 'assets/svg/list.svg';
import { ReactComponent as CalendarClock } from 'assets/svg/calendar-clock.svg';
import { ReactComponent as Checked } from 'assets/svg/checked.svg';
// IconsStatus
import { ReactComponent as Success } from 'assets/svg/success.svg';
import { ReactComponent as SuccessFilled } from 'assets/svg/success-filled.svg';
import { ReactComponent as Info } from 'assets/svg/info.svg';
import { ReactComponent as InfoFilled } from 'assets/svg/info-filled.svg';
import { ReactComponent as Warning } from 'assets/svg/warning.svg';
import { ReactComponent as WarningFilled } from 'assets/svg/warning-filled.svg';
import { ReactComponent as Error } from 'assets/svg/error.svg';
import { ReactComponent as ErrorFilled } from 'assets/svg/error-filled.svg';
// IconsCommon
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { ReactComponent as Minus } from 'assets/svg/minus.svg';
import { ReactComponent as Check } from 'assets/svg/check.svg';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { ReactComponent as CaretDown } from 'assets/svg/caret-down.svg';
import { ReactComponent as CaretRight } from 'assets/svg/caret-right.svg';
import { ReactComponent as ArrowRight } from 'assets/svg/arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/svg/arrow-left.svg';
import { ReactComponent as ArrowDown } from 'assets/svg/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/svg/arrow-up.svg';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as ZoomIn } from 'assets/svg/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'assets/svg/zoom-out.svg';
import { ReactComponent as SortUp } from 'assets/svg/sort-up.svg';
import { ReactComponent as SortDown } from 'assets/svg/sort-down.svg';
import { ReactComponent as Search } from 'assets/svg/search.svg';
import { ReactComponent as Edit } from 'assets/svg/edit.svg';
import { ReactComponent as User } from 'assets/svg/user.svg';
import { ReactComponent as CheckboxEmpty } from 'assets/svg/checkbox-empty.svg';
import { ReactComponent as Maximize } from 'assets/svg/maximize.svg';
import { ReactComponent as Minimize } from 'assets/svg/minimize.svg';
import { ReactComponent as Restart } from 'assets/svg/restart.svg';
import { ReactComponent as Delete } from 'assets/svg/delete.svg';
import { ReactComponent as Save } from 'assets/svg/save.svg';
import { ReactComponent as Hamburger } from 'assets/svg/hamburger.svg';
import { ReactComponent as ThreeDots } from 'assets/svg/three-dots.svg';
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg';
import { ReactComponent as Download } from 'assets/svg/download.svg';
import { ReactComponent as Filters } from 'assets/svg/filters.svg';
import { ReactComponent as Hourglass } from 'assets/svg/hourglass.svg';
import { ReactComponent as Locked } from 'assets/svg/locked.svg';
import { ReactComponent as Copy } from 'assets/svg/copy.svg';
import { ReactComponent as Settings } from 'assets/svg/settings.svg';
import { ReactComponent as ViewMode } from 'assets/svg/view-mode.svg';
import { ReactComponent as Pin } from 'assets/svg/pin.svg';
import { ReactComponent as Draft } from 'assets/svg/draft.svg';
import { ReactComponent as Legend } from 'assets/svg/legend.svg';
// IconsToolbar
import { ReactComponent as FullWidth } from 'assets/svg/full-width.svg'

export const IconsMenu = {
  Home,
  Filter,
  Note,
  List,
  CalendarClock,
  Checked,
};
export const IconsStatus = {
  Success,
  SuccessFilled,
  Info,
  InfoFilled,
  Warning,
  WarningFilled,
  Error,
  ErrorFilled,
};
export const IconsCommon = {
  Plus,
  Minus,
  Check,
  Close,
  CaretDown,
  CaretRight,
  ArrowRight,
  ArrowLeft,
  ArrowDown,
  ArrowUp,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  ZoomIn,
  ZoomOut,
  SortUp,
  SortDown,
  Search,
  Edit,
  User,
  CheckboxEmpty,
  Maximize,
  Minimize,
  Restart,
  Delete,
  Save,
  Hamburger,
  ThreeDots,
  Calendar,
  Download,
  Filters,
  Hourglass,
  Locked,
  Copy,
  Settings,
  ViewMode,
  Pin,
  Draft,
  Legend,
};
export const IconsToolbar = {
  FullWidth,
};

const Icons = {
  ...IconsMenu,
  ...IconsStatus,
  ...IconsCommon,
  ...IconsToolbar,
};

export type IconName = keyof typeof Icons;

export type IconProps = {
  name: IconName;
  size?: number | 'small';
} & SVGProps<SVGSVGElement>;

export const Icon = ({ name, size = 20, ...svgProps }: IconProps) => {
  const Component = Icons[name];
  const sizePixels = size === 'small' ? 16 : size;
  return (
    <Component
      width={sizePixels}
      height={sizePixels}
      // viewBox={`0 0 ${sizePixels} ${sizePixels}`}
      {...svgProps}
    />
  );
};
