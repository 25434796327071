import React, { CSSProperties, useRef } from 'react';
import ClickOutside from '../ClickOutside/ClickOutside';
import Portal from '../Portal/Portal';

type DropdownProps = {
  open: boolean;
  onClose: () => void;
  dropdown: React.ReactNode;
  children: React.ReactNode;
  width?: number;
};

export const Dropdown = ({
  open,
  onClose,
  children,
  dropdown,
  width,
}: DropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  // TODO: если значения поменяются, то ренендера не будет, нужно сделать так, чтобы был (через ResizeObserver)
  const { left, width: clientWidth, bottom, top } =
    anchorRef.current?.getBoundingClientRect() || {
      top: 0,
      left: 0,
      width: 0,
      bottom: 0,
    };
  const placeBottom = top < window.innerHeight / 2;

  const stylePlace: CSSProperties = placeBottom ? {
    top: bottom,
    maxHeight: window.innerHeight - bottom,
  } : {
    bottom: window.innerHeight - top,
    maxHeight: top,
  }

  const styleContainer: CSSProperties = {
    position: 'fixed',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    left,
    width: width || clientWidth,
    ...stylePlace
  };

  const handleClose = (event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node)) {
      onClose()
    }
  }

  return (
      <div ref={containerRef}>
        <div ref={anchorRef}>{children}</div>
        {open && (
          <Portal>
            <ClickOutside onClickOutside={handleClose}>
              <div style={styleContainer}>{dropdown}</div>
            </ClickOutside>
          </Portal>
        )}
      </div>
  );
};
