import React, {
  ChangeEvent,
  useEffect,
  useState,
  ClipboardEvent,
  KeyboardEvent,
  FocusEvent,
} from 'react';
import Big from 'big.js';
import { ButtonIcon } from '../ButtonIcon/ButtonIcon';
import { TextField, TextFieldProps } from '../TextField/TextField';

const toLocaleString = (value: number) => {
  // в локали ru-RU после по-умолчанию округление до 3-х знаков после запятой
  return value.toLocaleString('ru-RU', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 14,
  });
};

// TODO перенести этут функционал в TextField в handleChange
const findDifferentChar = (oldStr: string, newStr: string): string | null => {
  for (let i = 0; i < newStr.length; i++) {
    if (oldStr[i] !== newStr[i]) {
      return newStr[i];
    }
  }
  return null;
};

type NumberFieldProps = Omit<TextFieldProps, 'value' | 'handleChange'> & {
  label?: string;
  value: number | null;
  handleChange: (value: number | null) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  integerOnly?: boolean;
  step?: number;
  applyOnBlur?: boolean;
};

export function NumberField({
  label,
  value,
  handleChange,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  placeholder,
  min = -9007199254740991,
  max = 9007199254740991,
  disabled = false,
  integerOnly = false,
  step,
  applyOnBlur = false,
  ...otherInputProps
}: NumberFieldProps) {
  const [inputValue, setInputValue] = useState('');
  const [groupingSeparator, setGroupingSeparator] = useState(' ');
  const [decimalSeparator, setDecimalSeparator] = useState(',');
  const [isEditing, setIsEditing] = useState(false);

  // Detect locale decimal separator on mount
  useEffect(() => {
    const number = 1.1;
    const number1000 = 1000;
    const separator = number.toLocaleString().substring(1, 2);
    const groupSymbol = number1000.toLocaleString().substring(1, 2);
    setDecimalSeparator(separator);
    setGroupingSeparator(groupSymbol);
  }, []);

  // Update input value when external value changes
  useEffect(() => {
    if (value === null) {
      setInputValue('');
    } else {
      setInputValue(toLocaleString(value));
    }
  }, [value]);

  const parseLocaleNumber = (value: string): number | null => {
    const normalized = value
      .replace(new RegExp(groupingSeparator, 'g'), '')
      .replace(decimalSeparator, '.');
    const parsed = parseFloat(normalized);
    if (isNaN(parsed)) return null;
    return integerOnly ? Math.round(parsed) : parsed;
  };

  const isValidNumberString = (str: string): boolean => {
    const num = parseLocaleNumber(str);
    if (num === null) return false;
    if (num < min) return false;
    if (num > max) return false;
    return !isNaN(num);
  };

  const sanitizeNumberString = (str: string): string => {
    // For integer mode, simply remove all non-digit characters except minus
    if (integerOnly) {
      const sanitized = str.replace(/[^\d-]/g, '');
      return sanitized.includes('-')
        ? '-' + sanitized.replace(/-/g, '')
        : sanitized;
    }

    // For decimal numbers, handle locale-specific decimal separator
    let sanitized = str
      .replace(/[.,]/g, decimalSeparator)
      .replace(new RegExp(`[^\\d${decimalSeparator}-]`, 'g'), '');

    const parts = sanitized.split(decimalSeparator);
    if (parts.length > 2) {
      sanitized = parts[0] + decimalSeparator + parts.slice(1).join('');
    }

    if (sanitized.includes('-')) {
      sanitized = '-' + sanitized.replace(/-/g, '');
    }

    return sanitized;
  };

  const commitValue = (rawValue: string) => {
    const numberValue = parseLocaleNumber(rawValue);

    if (numberValue !== null) {
      if (min !== undefined && numberValue < min) return;
      if (max !== undefined && numberValue > max) return;
      setInputValue(toLocaleString(numberValue));
      if (value !== numberValue) {
        handleChange(numberValue);
      }
    } else {
      setInputValue('');
      handleChange(null);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    const newValue = e.target.value;
    setIsEditing(true);

    if (newValue === '') {
      setInputValue('');
      handleChange(null);
      return;
    }

    const lastChar = findDifferentChar(inputValue, newValue);

    const allowChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', null];
    if (!integerOnly) allowChars.push(decimalSeparator);

    if (
      !(allowChars.includes(lastChar) || newValue[0] === '-') ||
      new RegExp(`(\\${decimalSeparator}.*?){2,}`).test(newValue) // встречается ровно или больше 2 раз
    ) {
      return;
    }

    // тут позволяем ввести знак, но не изменяем значение
    if (
      lastChar === decimalSeparator ||
      (newValue[0] === '-' && !isValidNumberString(newValue))
    ) {
      setInputValue(newValue);
      return;
    }

    if (!isValidNumberString(newValue)) {
      return;
    }
    setInputValue(newValue);

    if (!applyOnBlur) {
      commitValue(newValue);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown?.(e);
    if (e.key === 'Enter') {
      e.preventDefault();
      setIsEditing(false);
      if (applyOnBlur) commitValue(inputValue);
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const sanitizedValue = sanitizeNumberString(pastedText);

    if (sanitizedValue === '') return;
    setInputValue(sanitizedValue);

    if (!applyOnBlur) {
      commitValue(sanitizedValue);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
    setIsEditing(false);
    if (applyOnBlur) commitValue(inputValue);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    onFocus?.(e);
    setIsEditing(true);
  };

  const increment = step
    ? () => {
        const currentValue = value ?? 0;
        const newValue = Big(currentValue).plus(step).toNumber();
        if (max !== undefined && newValue !== null && newValue > max) return;
        handleChange(newValue);
      }
    : undefined;

  const decrement = step
    ? () => {
        const currentValue = value ?? 0;
        const newValue = Big(currentValue).minus(step).toNumber();
        if (min !== undefined && newValue !== null && newValue < min) return;
        handleChange(newValue);
      }
    : undefined;

  return (
      <TextField
        label={label}
        value={inputValue}
        onChange={onChangeInput}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={disabled}
        endElements={
          step
            ? [
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <ButtonIcon
                    variant="additional"
                    iconName="Plus"
                    iconProps={{ size: 'small' }}
                    style={{ height: 16, padding: 0 }}
                    disabled={
                      disabled || (max !== undefined && (value ?? 0) >= max)
                    }
                    onClick={increment}
                  />
                  <ButtonIcon
                    variant="additional"
                    iconName="Minus"
                    iconProps={{ size: 'small' }}
                    style={{ height: 16, padding: 0 }}
                    disabled={
                      disabled || (min !== undefined && (value ?? 0) <= min)
                    }
                    onClick={decrement}
                  />
                </div>,
              ]
            : undefined
        }
        {...otherInputProps}
      />
  );
}
