import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonIcon } from '../../uikit/ButtonIcon/ButtonIcon';
import styles from './BreadCrumbs.module.css';

type BreadCrumb = {
  label: string;
  toLink?: string;
};

type BreadCrumbsProps = {
  backLink?: string;
  breadCrumbs?: (BreadCrumb | undefined)[];
};

export const BreadCrumbs = ({ backLink, breadCrumbs }: BreadCrumbsProps) => {

  const breadCrumbsFiltered = (breadCrumbs?.filter(Boolean) || []) as BreadCrumb[];

  return (
    <>
      <div className={styles.BreadCrumbsPanel}>
        {backLink && (
          <Link to={backLink}>
            <ButtonIcon iconName="ArrowLeft" variant="additional" rounded />
          </Link>
        )}
        {breadCrumbsFiltered.length > 0 && (
          <div
            className={styles.BreadCrumbsWrap}
            style={{ marginLeft: backLink ? 16 : 0 }}
          >
            {breadCrumbsFiltered.map((breadCrumb, index) => (
              <div key={breadCrumb.label + index} className={styles.BreadCrumbs}>
                {index !== 0 && (
                  <div className={styles.Divider}>/</div>
                )}
                {breadCrumb.toLink ? (
                  <Link className={styles.Link} to={breadCrumb.toLink}>
                    {breadCrumb.label}
                  </Link>
                ) : (
                  breadCrumb.label
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
