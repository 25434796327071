import React from 'react';
import { FormsList, IForm } from '../../../models/list-forms-model';
import { FieldType } from '../../../types/common/form';
import { Option, SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import { FieldEdit } from '../FormFields/FieldEdit';
import { FieldReadonly } from '../FormFields/FieldReadonly';
import { ListFormController } from '../ListFormController/ListFormController';
import styles from './CardForm.module.css';

export interface FormField {
  code: string;
  label: string;
  type: FieldType;
  oldValue: string | null;
  value: string | null;
  options?: Option[];
}

type CardFormSimpleProps = {
  form: IForm;
  fields: FormField[];
  setFieldValue: (fieldCode: string, value: string | null) => void;
  isReadOnly: boolean;
};

export const CardFormSimple = ({
  form,
  fields,
  setFieldValue,
  isReadOnly,
}: CardFormSimpleProps) => {

  const form2 = FormsList.find((form) => form.code === 'unit');

  return (
    <div className={styles.CardForm}>
      <div className={styles.Header}>{form.name}</div>
      <div className={styles.Form}>
        <div className={styles.Container}>
          {fields.map((field) => (
            <div key={field.code} className={styles.FieldContainer}>
              <div className={styles.LabelWrap}>{field.label}:</div>
              <div className={styles.InputWrap}>
                {isReadOnly ? (
                  <div className={styles.Value}><FieldReadonly field={field} /></div>
                ) : (
                  <FieldEdit field={field} setFieldValue={setFieldValue} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*<div style={{ height: 300 }}>*/}
      {/*  <ListFormController form={form2!} />*/}
      {/*</div>*/}
    </div>
  );
};
